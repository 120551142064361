<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/trade.png" alt />
        <span class="center">财务功能</span>
        <img src="../../assets/right.png" alt />
        <span class="center">创客累计流水统计</span>
      </div>
      <div style="display: flex; align-item: center">
        <el-button type="primary" size="mini" @click="exportList()">导出</el-button>
      </div>
    </div>
    <el-card>
      <ListFilter :total="total" @close="searchReset">
        关键字：
        <el-input
          placeholder="请输入创客名称"
          v-model="keywords"
          @input="keywordsChange"
          size="mini"
          style="margin-right: 10px; width: 140px"
          prefix-icon="el-icon-search"
        ></el-input>时间范围：
        <el-radio-group v-model="dateRadio" @change="radioChange()">
          <el-radio-button label="今天" size="mini"></el-radio-button>
          <el-radio-button label="本周" size="mini"></el-radio-button>
          <el-radio-button label="本月" size="mini"></el-radio-button>
          <el-radio-button label="本年" size="mini"></el-radio-button>
          <el-radio-button label="自定义" size="mini"></el-radio-button>
        </el-radio-group>

        <el-date-picker
          v-if="dateRadio === '自定义'"
          :clearable="false"
          @blur="searchChange($event)"
          v-model="dateTime"
          type="daterange"
          size="mini"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
        ></el-date-picker>结算中心：
        <el-select :value="pay_platform" @change="platformChange" style="width: 230px" size="mini">
          <el-option
            v-for="(item, index) in platformList"
            :key="index"
            :label="item.shortname"
            :value="item.pay_platform"
          ></el-option>
        </el-select>企业：
        <el-select
          v-model="enterpriseCode"
          remote
          reserve-keyword
          :remote-method="enterpriseRemote"
          @change="enterpriseChange"
          style="width: 230px"
          size="mini"
          filterable
          clearable
        >
          <el-option v-for="item in eList" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </ListFilter>

      <div class="function-descption">
        <p>
          根据筛选条件 ： 累计支付人数{{ total }}人 ，累计支付次数{{
          totalInfo.times
          }}次 ，累计支付金额{{ totalInfo.totalAmount | money }}元
        </p>
      </div>
      <el-table border :data="list" style="width: 100%">
        <el-table-column label="创客姓名">
          <template slot-scope="scope">
            <span class="spanColor" @click="getDetail(scope.row)">
              {{
              scope.row.userName
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="创客电话">
          <template slot-scope="scope">
            <span>{{ scope.row.userPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userIdCardNumber" label="创客身份证"></el-table-column>
        <el-table-column label="累计金额/元">
          <template slot-scope="scope">{{ scope.row.amount | money }}</template>
        </el-table-column>
        <el-table-column label="个税/元">
          <template slot-scope="scope">{{ scope.row.personTax | money }}</template>
        </el-table-column>
        <el-table-column prop="times" label="支付次数"></el-table-column>
        <el-table-column label="发放企业">
          <template slot-scope="scope">
            <span>{{ scope.row.enterpriseName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结算中心">
          <template slot-scope="scope">{{ scope.row.pay_platform | platformVal }}</template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { parseJsonToTable } from '../../lib/dev';
import moment from 'moment';
import { mapState, mapActions, mapMutations } from 'vuex';
import { api } from '/src/api/base';
export const enterpriseSimpleList = api()('enterprise.simple.list.json');
export default {
  data () {
    return {
      dateRadio: '本月',
      dateTime: this.dateTime,
      pageNumber:1,
      newTime: '',
      eList: [],
      enterpriseName: ''
    };
  },
  created () {
    this.setPlatformList(this.$route.params.$preload.platformList);
    this.setPlatform(this.$route.params.$preload.platformList[0].pay_platform);
    this.init();
  },
  computed: {
    ...mapState('statisticsMaker', [
      'keywords',
      'enterpriseCode',
      'pay_platform',
      'platformList',
      'startTime',
      'endTime',
      'pageSize',
      'total',
      'list',
      'amount',
      'pageSize',
      
      'totalInfo',
      'timeName'
    ])
  },
  methods: {
    enterpriseRemote (keywords) {
      this.getEnterpriseList(keywords);
    },
    async getEnterpriseList (keywords) {
      const res = await enterpriseSimpleList({
        pageNumber: 1,
        pageSize: 20,
        keywords
      });
      this.eList = res.list;
    },
    handleCurrentChange (val) {
      this.setPageNumber(val);
      this.updateList();
    },
    enterpriseChange (v) {
      this.setEnterpriseCode(v);
      if (v) {
        this.enterpriseName = this.eList.find(
          it => it.code === this.enterpriseCode
        ).name;
      } else {
        this.setPageNumber(1);
      }
      this.updateList();
      this.getTotalInfo();
    },
    ...mapActions('statisticsMaker', ['init', 'updateList', 'getTotalInfo']),
    ...mapMutations('statisticsMaker', [
      'setKeywords',
      'setPageSize',
      'setPageNumber',
      'setClear',
      'setPlatform',
      'setPlatformList',
      'setEnterpriseCode',
      'setMakerInfo',
      'setDateTime',
      'setTimeName'
    ]),
    handleSizeChange (val) {
      this.setPageSize(val);
      this.updateList();
    },
    keywordsChange (v) {
      this.pageNumber = 1;
      this.setKeywords(v);
      this.updateList();
      this.getTotalInfo();
    },
    platformChange (v) {
      this.setPlatform(v);
      this.updateList();
      this.getTotalInfo();
    },
    searchReset () {
      this.dateRadio = '本月';
      this.setClear();
      this.updateList();
      this.getTotalInfo();
    },
    getDetail (row) {
      this.setMakerInfo(row);
      this.$router.push({
        path: '/system/trade/userStatisticsInfo'
      });
    },
    radioChange () {
      this.setClear();
      this.setTimeName(this.dateRadio);
      if (this.dateRadio === '今天') {
        const startDate = moment().format('YYYY-MM-DD');
        const endDate = moment().format('YYYY-MM-DD');
        this.setDateTime([startDate, endDate]);
        this.updateList();
        this.getTotalInfo();
      }
      if (this.dateRadio === '本周') {
        const startDate = moment()
          .week(moment().week())
          .startOf('week')
          .format('YYYY-MM-DD');
        const endDate = moment()
          .week(moment().week())
          .endOf('week')
          .format('YYYY-MM-DD');
        this.setDateTime([startDate, endDate]);
        this.updateList();
        this.getTotalInfo();
      }
      if (this.dateRadio === '本月') {
        const startDate = moment()
          .month(moment().month())
          .startOf('month')
          .format('YYYY-MM-DD');
        const endDate = moment()
          .month(moment().month())
          .endOf('month')
          .format('YYYY-MM-DD');
        this.setDateTime([startDate, endDate]);
        this.updateList();
        this.getTotalInfo();
      }
      if (this.dateRadio === '本年') {
        const startDate = moment()
          .year(moment().year())
          .startOf('year')
          .format('YYYY-MM-DD');
        const endDate = moment()
          .year(moment().year())
          .endOf('year')
          .format('YYYY-MM-DD');
        this.setDateTime([startDate, endDate]);
        this.updateList();
        this.getTotalInfo();
      }
    },
    searchChange () {
      this.setDateTime(this.dateTime);
      this.updateList();
      this.getTotalInfo();
    },
    async exportList () {
      let _list = [];
      if (this.total > 500) {
        this.setPageSize(500);
        for (let i = 1; i < this.total / this.pageSize + 1; i++) {
          this.setPageNumber(i);
          await this.updateList();
          _list = [..._list, ...this.list];
        }
      } else {
        this.setPageSize(500);
        this.setPageNumber(1);
        await this.updateList();
        _list = this.list;
      }
      if (_list.length === 0) {
        return this.$message.error('暂无要导出的数据！');
      }
      _list = _list.map(it => {
        return {
          ...it
        };
      });
      const time = moment().format('YYMMDDHH');
      // if (this.dateRadio === "本月") {
      parseJsonToTable(
        `创客流水记录_${time}`,
        {
          姓名: 'userName',
          手机号码: 'userPhone',
          身份证号: 'userIdCardNumber',
          累计金额: 'amount',
          个税: 'personTax',
          支付次数: 'times',
          支付企业: 'enterpriseName',
          结算中心: it => {
            if (it.pay_platform === 'm1') {
              return '陕西结算中心';
            }
            if (it.pay_platform === 'm2') {
              return '青岛结算中心';
            }
            if (it.pay_platform === 'm3') {
              return '江西结算中心';
            }
            if (it.pay_platform === 'p1') {
              return '银企直连智能清分子账户代发专用';
            }
          }
        },
        _list,
        true
      );
      // }
      // if (
      //   this.dateRadio === "今天" ||
      //   this.dateRadio === "本周" ||
      //   this.dateRadio === "本年" ||
      //   this.dateRadio === "今天" ||
      //   this.dateRadio === "自定义"
      // ) {
      //   parseJsonToTable(
      //     `创客流水记录_${time}`,
      //     {
      //       姓名: "userName",
      //       手机号码: "userPhone",
      //       身份证号: "userIdCardNumber",
      //       累计金额: "amount",
      //       支付次数: "times",
      //       支付企业: "enterpriseName",
      //       结算中心: it => {
      //         if (it.pay_platform === "1") {
      //           return "陕西结算中心";
      //         }
      //         if (it.pay_platform === "2") {
      //           return "青岛结算中心";
      //         }
      //         if (it.pay_platform === "3") {
      //           return "江西结算中心";
      //         }
      //       }
      //     },
      //     _list,
      //     true
      //   );
      // }
    }
  },
  components: {
    ListFilter: () => import('/src/components/filter')
  }
};
</script>
<style>
.el-radio-button__inner {
  padding: 7px 20px !important;
  font-size: 12px !important;
  color: #606266;
}
.el-radio-button:first-child .el-radio-button__inner,
.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0;
}
.el-range-editor--mini.el-input__inner {
  top: 1px;
}
</style>
<style scoped lang="scss">
.spanColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
